import { React } from "react"

function ContactInfos() {
  return (
    <div data-scroll className="max-container c-infos-contact">
      <div className="infos-item">
        <h2>Find us</h2>
        <ul>
          <li>
            Blue Node Paris <br />
            50 rue Jean-Pierre Timbaud 75011 Paris
          </li>
        </ul>
      </div>
      <div className="infos-item">
        <h2>Get in touch</h2>
        <ul className="t-decoration">
          <li>
            <a href="mailto:projects@bluenode.paris">projects@bluenode.paris</a>
          </li>
          <li>
            <a href="mailto:talents@bluenode.paris">talents@bluenode.paris</a>
          </li>
        </ul>
      </div>
      <div className="infos-item">
        <h2>Follow us</h2>

        <ul>
          <li>
            <a
              href="https://www.instagram.com/bluenode.paris/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/bluenodeparis/"
              target="_blank"
              rel="noreferrer"
            >
              Linkedin
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ContactInfos
