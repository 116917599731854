import React, { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"

import { isMobile } from "react-device-detect"
import { useLocomotiveScroll } from "react-locomotive-scroll"
import { motion } from "framer-motion"
import { useConfigProvider } from "../../contexts/config-context"
import SvgBg from "../../components/svgBg"

import Footer from "../../components/Footer"
import LoaderAnime from "../../components/LoaderAnime"
import Cover from "../../components/Cover"
import WorksList from "../../components/WorksList"
import Navigation from "../../components/Navigation"
import { SetPageName, mouseEffect } from "../../helpers"
import SentencesInteraction from "../../components/SentencesInteraction"

const { REACT_APP_MEDIA_URL } = process.env

function Home({ activeEffect, scrollRef, references }, props) {
  const metaTitle = "Creative Studio"
  const metaDescription =
    "Blue Node is a creative independent studio. We design augmented imagery, rendered in all dimensions."

  const location = useLocation()
  const SvgBg1 = useRef(null)
  const SvgBg2 = useRef(null)
  const SvgBg3 = useRef(null)
  const cover = useRef(null)

  const { loadingVideo, setLoadingVideo } = useConfigProvider()

  const { scroll } = useLocomotiveScroll()

  const handleWatchSentences = (obj, id) => {
    if (typeof obj.currentElements[id] === "object" && isMobile) {
      const { progress } = obj.currentElements[id]

      if (progress > 0.2 && progress < 0.85) {
        document
          .querySelector(`#w-master-${id} .w-sentence-path`)
          .classList.add("animeMobileSentence")
      } else {
        document
          .querySelector(`#w-master-${id} .w-sentence-path`)
          .classList.remove("animeMobileSentence")
      }
    }
  }

  useEffect(() => {
    if (scrollRef.current !== null) {
      scrollRef.current.on("scroll", (obj) => {
        mouseEffect.rotate(activeEffect, scroll, obj)
        handleWatchSentences(obj, "sentence1")
        handleWatchSentences(obj, "sentence2")
        handleWatchSentences(obj, "sentence3")
      })

      scrollRef.current.on("call", (obj) => {
        if (obj === "stopSound" && !cover.current.muted()) {
          cover.current.setMuted(false)
        }
      })
    }
  }, [activeEffect, location.href, scrollRef, scroll])

  useEffect(() => {
    mouseEffect.toggle()
    return () => {
      mouseEffect.hidden()
    }
  }, [])

  return (
    <>
      <LoaderAnime />

      <SetPageName metaTitle={metaTitle} metaDescription={metaDescription} />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: isMobile ? 0 : 0, delay: 0.5 }}
      >
        <Cover
          ref={cover}
          loadingVideo={loadingVideo}
          setLoadingVideo={setLoadingVideo}
          scrollRef={scrollRef}
          video={`${
            !isMobile
              ? `${REACT_APP_MEDIA_URL}/homepage.mp4`
              : `${REACT_APP_MEDIA_URL}/homepage-mobile.mp4`
          }`}
          image={`${
            !isMobile
              ? `${REACT_APP_MEDIA_URL}/preview-video-homepage`
              : `${REACT_APP_MEDIA_URL}/preview-video-homepage-mobile`
          }`}
        />

        <section
          data-scroll-section
          id="studio"
          className="s-home-sentences section"
          data-scroll-id="homeSentences"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.5 }}
          >
            <div
              id="s-big-sentences-clone"
              className="section-clone big-sentences-clone clip"
            >
              <Navigation
                {...props}
                scrollTarget="#s-big-sentences-clone"
                color="black"
                zindex="z-10"
              />
            </div>
          </motion.div>

          <div className="max-container">
            <div
              data-scroll
              data-scroll-speed="2"
              data-scroll-sticky
              data-scroll-target="#studio"
            >
              <SvgBg id="bg-clipping1" ref={SvgBg1} />
              <SvgBg id="bg-clipping2" ref={SvgBg2} />
              <SvgBg id="bg-clipping3" ref={SvgBg3} />
              <SentencesInteraction scrollRef={scrollRef} />
            </div>

            <div
              className="w-sentence w-master-sentence"
              id="w-master-sentence1"
              data-scroll
              data-scroll-speed="0"
              data-scroll-call="homeAnime"
            >
              <div className="c-sticky-sentence" data-scroll>
                <div className="w-sticky-sentence">
                  <div
                    className="w-sentence-item"
                    style={{ clipPath: "initial" }}
                  >
                    <p
                      className="sentence"
                      data-scroll-id="sentence1"
                      data-scroll-repeat
                      data-scroll-call="stopSound"
                      data-scroll
                      data-scroll-delay="1"
                      data-scroll-speed="1"
                      style={{ color: "black" }}
                      data-scroll-sticky
                      data-scroll-target="#w-master-sentence1"
                    >
                      <span className="stroke">
                        Blue Node is a creative independent studio. We design
                        augmented imagery, rendered in all dimensions.
                      </span>
                    </p>
                  </div>

                  <div className="w-sentence-item w-sentence-path">
                    <p
                      className="sentence"
                      data-scroll
                      data-scroll-delay="1"
                      data-scroll-speed="1"
                      style={{ color: "black" }}
                      data-scroll-sticky
                      data-scroll-target="#w-master-sentence1"
                    >
                      <span
                        className="stroke"
                        data-scroll
                        data-scroll-repeat
                        data-scroll-call="playSentence1"
                        data-scroll-offset="0,70%"
                      >
                        Blue Node is a creative independent studio. We design
                        augmented imagery, rendered in all dimensions.
                      </span>
                      <span className="stroke-offset">
                        Blue Node is a creative independent studio. We design
                        augmented imagery, rendered in all dimensions.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-sentence w-master-sentence"
              id="w-master-sentence2"
              data-scroll
              data-scroll-speed="0"
              data-scroll-call="homeAnime"
              data-scroll-class="sentence"
            >
              <div className="c-sticky-sentence">
                <div className="w-sticky-sentence">
                  <div
                    className="w-sentence-item"
                    style={{ clipPath: "initial" }}
                  >
                    <p
                      className="sentence"
                      data-scroll-id="sentence2"
                      data-scroll
                      data-scroll-delay="1"
                      data-scroll-speed="1"
                      style={{ color: "black" }}
                      data-scroll-sticky
                      data-scroll-target="#w-master-sentence2"
                    >
                      <span className="stroke">
                        Our team made up of creatives, designers, developers &
                        digital producers brings high-quality, tailor-made
                        experiences to life.
                      </span>
                    </p>
                  </div>

                  <div className="w-sentence-item w-sentence-path">
                    <p
                      className="sentence"
                      data-scroll
                      data-scroll-delay="1"
                      data-scroll-speed="1"
                      style={{ color: "black" }}
                      data-scroll-sticky
                      data-scroll-target="#w-master-sentence2"
                    >
                      <span
                        className="stroke"
                        data-scroll
                        data-scroll-repeat
                        data-scroll-call="playSentence2"
                        data-scroll-offset="0,60%"
                      >
                        Our team made up of creatives, designers, developers &
                        digital producers brings high-quality, tailor-made
                        experiences to life.
                      </span>
                      <span className="stroke-offset">
                        Our team made up of creatives, designers, developers &
                        digital producers brings high-quality, tailor-made
                        experiences to life.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-sentence w-master-sentence"
              id="w-master-sentence3"
              data-scroll
              data-scroll-speed="0"
              data-scroll-call="homeAnime"
            >
              <div className="c-sticky-sentence">
                <div className="w-sticky-sentence">
                  <div
                    className="w-sentence-item"
                    style={{ clipPath: "initial" }}
                  >
                    <p
                      className="sentence"
                      data-scroll-id="sentence3"
                      data-scroll
                      data-scroll-delay="1"
                      data-scroll-speed="1"
                      style={{ color: "black" }}
                      data-scroll-sticky
                      data-scroll-target="#w-master-sentence3"
                    >
                      <span className="stroke">
                        Blue Node operates within physical and virtual realities
                        to shape immersive worlds.
                      </span>
                    </p>
                  </div>

                  <div className="w-sentence-item w-sentence-path">
                    <p
                      className="sentence"
                      data-scroll
                      data-scroll-delay="1"
                      data-scroll-speed="1"
                      style={{ color: "black" }}
                      data-scroll-sticky
                      data-scroll-target="#w-master-sentence3"
                    >
                      <span
                        className="stroke"
                        data-scroll
                        data-scroll-repeat
                        data-scroll-call="playSentence3"
                        data-scroll-offset="0,60%"
                      >
                        Blue Node operates within physical and virtual realities
                        to shape immersive worlds.
                      </span>
                      <span className="stroke-offset">
                        Blue Node operates within physical and virtual realities
                        to shape immersive worlds.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-sentence w-sentence-empty w-master-sentence"
              data-scroll
              data-scroll-speed="1"
            >
              <div className="w-sentence-item" />
            </div>
          </div>
        </section>

        <WorksList
          {...props}
          loadingVideo={loadingVideo}
          setLoadingVideo={setLoadingVideo}
          references={references}
          highlight
          color="black"
        />

        <Footer {...props} />
      </motion.div>
    </>
  )
}

export default Home
